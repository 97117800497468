

































import {Component, Vue} from "vue-property-decorator";
import Client from "@/app/Http/Client";

@Component
export default class Sidebar extends Vue {
    signOut(): void {
        Client.get('/azure/logout').then(res => {
            window.localStorage.removeItem('token');
            //@ts-ignore
            window.location.href = res.data;
        });
    }
}
